var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mods && _vm.mod
    ? _c(
        "div",
        [
          _c("context-title", {
            attrs: { title: _vm.pageTitle, passedActions: _vm.titleBarActions },
          }),
          _c("key-val-line", { attrs: { prop: "Enabled" } }, [
            _vm._v(" " + _vm._s(_vm.mod.enabled ? "Yes" : "No") + " "),
          ]),
          _c("key-val-line", { attrs: { prop: "Item ID" } }, [
            _vm._v(" " + _vm._s(_vm.mod.sku) + " "),
          ]),
          _c("key-val-line", { attrs: { prop: "Path" } }, [
            _vm._v(" " + _vm._s(_vm.mod.path) + " "),
          ]),
          _c(
            "key-val-line",
            { attrs: { prop: "Tax (Default)" } },
            [
              _c("toggle", {
                staticClass: "d-inline-block block-xs--md block-xs--xs-right",
                attrs: { disabled: true, value: _vm.mod.taxRate },
              }),
              _vm._v(
                " Default (" +
                  _vm._s(_vm._f("parseTaxRate")(_vm.mod.taxRate)) +
                  "%) "
              ),
            ],
            1
          ),
          _c("key-val-line", { attrs: { prop: "Mod Variations" } }, [
            _vm._v(" Yield variation list "),
          ]),
          _c("key-val-line", { attrs: { prop: "Platform" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("spreadItems")(
                    _vm._f("extractDisplay")(_vm.mod.platforms)
                  )
                ) +
                " "
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Options" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("spreadItems")(
                    _vm._f("extractDisplay")(_vm.mod.options)
                  )
                ) +
                " "
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Time" } }, [
            _c("div", { staticClass: "col-7 col-md-5" }, [
              _c("div", { staticClass: "row no-gutters" }, [
                _c("div", { staticClass: "col-6" }, [_vm._v("Start")]),
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(_vm._s(_vm.mod.startTime)),
                ]),
              ]),
              _c("div", { staticClass: "row no-gutters" }, [
                _c("div", { staticClass: "col-6" }, [_vm._v("End")]),
                _c("div", { staticClass: "col-6" }, [
                  _vm._v(_vm._s(_vm.mod.endTime)),
                ]),
              ]),
            ]),
          ]),
          _c("key-val-line", { attrs: { prop: "Attributes" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("spreadItems")(
                    _vm._f("extractDisplay")(_vm.mod.attributes)
                  )
                ) +
                " "
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
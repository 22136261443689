<template>
    <div v-if="mods && mod">
        <context-title 
            :title="pageTitle" 
            :passedActions="titleBarActions" />

        <key-val-line prop="Enabled">
            {{ mod.enabled ? 'Yes' : 'No' }}
        </key-val-line>

        <key-val-line prop="Item ID">
            {{ mod.sku }}
        </key-val-line>

        <key-val-line prop="Path">
            {{ mod.path }}
        </key-val-line>

        <key-val-line prop="Tax (Default)">
            <toggle 
                :disabled="true" 
                :value="mod.taxRate" 
                class="d-inline-block block-xs--md block-xs--xs-right" />
            Default ({{ mod.taxRate | parseTaxRate }}%)
        </key-val-line>

        <key-val-line prop="Mod Variations">
            Yield variation list
        </key-val-line>

        <key-val-line prop="Platform">
            {{ mod.platforms | extractDisplay | spreadItems }}
        </key-val-line>

        <key-val-line prop="Options">
            {{ mod.options | extractDisplay | spreadItems }}
        </key-val-line>

        <key-val-line prop="Time">
            <div class="col-7 col-md-5">
                <div class="row no-gutters">
                    <div class="col-6">Start</div>
                    <div class="col-6">{{ mod.startTime }}</div>
                </div>

                <div class="row no-gutters">
                    <div class="col-6">End</div>
                    <div class="col-6">{{ mod.endTime }}</div>
                </div>
            </div>
        </key-val-line>

        <key-val-line prop="Attributes">
            {{ mod.attributes | extractDisplay | spreadItems }}
        </key-val-line>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { parseTaxRate, spreadItems } from 'helpers'
import contextTitle from 'components/context_title.vue'
import keyValLine from 'components/key_val_line.vue'
import modGroup from 'components/mods/mod_group.vue'
import toggle from 'components/cleverly/Toggle.vue'
import formInput from 'components/cleverly/FormInput.vue'
import lineItem from 'components/line_item.vue'
import textButton from 'components/text_button.vue'

export default {
    name: 'StoreMod',
    data() {
        return {
            titleBarActions: [
                {
                    type: 'edit',
                    display: 'Edit',
                    run: () => {
                        this.$router.push({name: 'store-mod-edit'})
                    },
                },
            ],
        }
    },
    computed: {
        mod() {
            const id = this.$route.params.mod
            const ret = this.mods.filter(mod => mod.id === id)[0]

            return ret
        },
        pageTitle() {
            return [
                {to: {name: 'store-mod-index'}, label: 'Mods'},
                this.mod.title,
            ]
        },
        ...mapState({
            mods: state => state.mods,
        }),
    },
    filters: {
        parseTaxRate,
        spreadItems,
        extractDisplay: arr => arr.filter(el => el.active).map(el => el.display),
    },
    components: {
        contextTitle,
        keyValLine,
        modGroup,
        toggle,
        formInput,
        lineItem,
        textButton,
    },
}
</script>

<style lang="scss">
</style>
